<template>
  <div 
    class="apple-toolbar"
    :class="{ 
      'with-back-button': !hideBackButton, 
      'large-title': useLargeTitle, 
      'compact': compact,
      'transparent': transparent,
      'dark-mode': darkMode
    }"
  >
    <!-- Status Bar Space (if needed) -->
    <div v-if="includeStatusBarSpace" class="status-bar-space"></div>
    
    <!-- Main Toolbar -->
    <div class="toolbar-container">
      <!-- Leading/Left Section -->
      <div class="toolbar-leading">
        <transition name="fade">
          <button
            v-if="!hideBackButton"
            type="button"
            class="back-button"
            @click="goBack"
            aria-label="Go back"
          >
            <svg class="back-icon" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 19L8 12L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span v-if="backTitle" class="back-title">{{ backTitle }}</span>
          </button>
        </transition>
        
        <!-- Custom leading content -->
        <slot name="leading"></slot>
      </div>
      
      <!-- Title Section -->
      <div class="toolbar-title">
        <h1 
          class="title-text"
          :style="{ 
            opacity: titleOpacity 
          }"
        >{{ title }}</h1>
      </div>
      
      <!-- Trailing/Right Section -->
      <div class="toolbar-trailing">
        <slot name="actions"></slot>
      </div>
    </div>
    
    <!-- Large Title Section (iOS-style) -->
    <div v-if="useLargeTitle" class="large-title-container" ref="largeTitleContainer">
      <div class="large-title-inner" ref="largeTitleInner">
        <slot name="subtitle">
          <div v-if="subtitle" class="toolbar-subtitle">{{ subtitle }}</div>
        </slot>
        <h1 class="large-title-text">{{ title }}</h1>
        <div v-if="$slots.search" class="search-container">
          <slot name="search"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps({
  title: {
    type: String,
    default: 'App Title'
  },
  subtitle: {
    type: String,
    default: ''
  },
  hideBackButton: {
    type: Boolean,
    default: false
  },
  backTitle: {
    type: String,
    default: ''
  },
  useLargeTitle: {
    type: Boolean,
    default: false
  },
  compact: {
    type: Boolean,
    default: false
  },
  transparent: {
    type: Boolean,
    default: false
  },
  darkMode: {
    type: Boolean,
    default: false
  },
  includeStatusBarSpace: {
    type: Boolean,
    default: true
  },
  scrollElement: {
    type: String,
    default: 'window'
  }
});

const emit = defineEmits(['back']);

const router = useRouter();
const largeTitleContainer = ref<HTMLElement | null>(null);
const largeTitleInner = ref<HTMLElement | null>(null);
const titleOpacity = ref(props.useLargeTitle ? 0 : 1);
const scrollListener = ref<any>(null);

// Go back function
const goBack = () => {
  emit('back');
  router.back();
};

// Handle scroll for large title effect
const handleScroll = () => {
  if (!props.useLargeTitle || !largeTitleContainer.value) return;
  
  const container = largeTitleContainer.value;
  const containerHeight = container.offsetHeight;
  const scrollPosition = props.scrollElement === 'window' 
    ? window.scrollY 
    : (document.querySelector(props.scrollElement)?.scrollTop || 0);
  
  // Calculate opacity transition for title
  const opacity = Math.min(scrollPosition / (containerHeight * 0.6), 1);
  titleOpacity.value = opacity;
  
  // Apply parallax/scaling effect for large title
  if (largeTitleInner.value) {
    const translateY = Math.max(scrollPosition / 2, 0);
    const scale = Math.max(1 - scrollPosition / containerHeight, 0.8);
    largeTitleInner.value.style.transform = `translateY(${translateY}px) scale(${scale})`;
    largeTitleInner.value.style.opacity = `${1 - (opacity * 1.5)}`;
  }
};

// Setup scroll listener
onMounted(() => {
  const scrollTarget = props.scrollElement === 'window' 
    ? window 
    : document.querySelector(props.scrollElement);
    
  if (scrollTarget) {
    scrollListener.value = handleScroll;
    scrollTarget.addEventListener('scroll', scrollListener.value);
    // Initial call to set correct positions
    handleScroll();
  }
});

// Clean up scroll listener
onBeforeUnmount(() => {
  const scrollTarget = props.scrollElement === 'window' 
    ? window 
    : document.querySelector(props.scrollElement);
    
  if (scrollTarget && scrollListener.value) {
    scrollTarget.removeEventListener('scroll', scrollListener.value);
  }
});

// Watch for changes in useLargeTitle
watch(() => props.useLargeTitle, (newValue) => {
  if (!newValue) {
    titleOpacity.value = 1;
  } else {
    // Reset scroll position on toggle
    handleScroll();
  }
});
</script>

<style scoped>
.apple-toolbar {
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'SF Pro Text', 'Helvetica Neue', sans-serif;
  background-color: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  transition: height 0.3s ease, background-color 0.3s ease;
}

.apple-toolbar.dark-mode {
  background-color: rgba(25, 25, 25, 0.85);
  border-bottom-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}

.apple-toolbar.transparent {
  background-color: transparent;
  backdrop-filter: none;
  -webkit-backdrop-filter: none;
  border-bottom: none;
}

.status-bar-space {
  height: env(safe-area-inset-top, 0);
  min-height: 20px; /* Fallback */
}

.toolbar-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  padding: 0 16px;
}

.apple-toolbar.compact .toolbar-container {
  height: 38px;
}

.toolbar-leading, .toolbar-trailing {
  display: flex;
  align-items: center;
  min-width: 70px;
  z-index: 1; /* Ensure buttons are clickable above title */
}

.toolbar-leading {
  justify-content: flex-start;
}

.toolbar-trailing {
  justify-content: flex-end;
}

.toolbar-title {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none; /* Allow clicks to pass through to buttons */
}

.title-text {
  font-size: 17px;
  font-weight: 600;
  letter-spacing: -0.01em;
  color: #1d1d1f;
  margin: 0;
  padding: 0;
  max-width: 60%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  transition: opacity 0.2s ease;
}

.dark-mode .title-text {
  color: #ffffff;
}

.back-button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  padding: 8px 8px 8px 0;
  cursor: pointer;
  color: #007aff;
  font-size: 17px;
  font-weight: 400;
  transition: opacity 0.2s ease;
  margin-left: -8px;
}

.dark-mode .back-button {
  color: #0a84ff;
}

.back-button:hover {
  opacity: 0.7;
}

.back-button:active {
  opacity: 0.5;
}

.back-icon {
  width: 20px;
  height: 20px;
  stroke-width: 2px;
}

.back-title {
  margin-left: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}

/* Large Title Styles */
.large-title-container {
  height: 52px;
  padding: 0 16px;
  overflow: hidden;
  transition: height 0.3s ease;
}

.large-title-inner {
  padding-bottom: 8px;
  transform-origin: left bottom;
  transition: transform 0.1s ease, opacity 0.1s ease;
}

.toolbar-subtitle {
  font-size: 13px;
  color: #6e6e73;
  margin-bottom: 4px;
}

.dark-mode .toolbar-subtitle {
  color: #98989d;
}

.large-title-text {
  font-size: 34px;
  font-weight: 700;
  line-height: 1.1;
  letter-spacing: -0.02em;
  color: #1d1d1f;
  margin: 0;
  padding: 0;
}

.dark-mode .large-title-text {
  color: #ffffff;
}

.search-container {
  margin-top: 10px;
  margin-bottom: 6px;
}

/* Animation for back button */
.fade-enter-active, 
.fade-leave-active {
  transition: opacity 0.2s ease, transform 0.2s ease;
}

.fade-enter-from, 
.fade-leave-to {
  opacity: 0;
  transform: translateX(-8px);
}
</style>