const __pages_import_0__ = () => import("/src/pages/events/main/scanner.vue");
const __pages_import_1__ = () => import("/src/pages/success/index.vue");
const __pages_import_2__ = () => import("/src/pages/reports/useReportData.ts");
const __pages_import_3__ = () => import("/src/pages/reports/useChartOptions.ts");
const __pages_import_4__ = () => import("/src/pages/reports/useChartCreation.ts");
const __pages_import_5__ = () => import("/src/pages/reports/index.vue");
const __pages_import_6__ = () => import("/src/pages/reports/RoutineCyclesReport.vue");
const __pages_import_7__ = () => import("/src/pages/link/index.vue");
const __pages_import_8__ = () => import("/src/pages/error/index.vue");
const __pages_import_9__ = () => import("/src/pages/dashboard/useExport.ts");
const __pages_import_10__ = () => import("/src/pages/dashboard/useEvents.ts");
const __pages_import_11__ = () => import("/src/pages/dashboard/index.vue");
const __pages_import_12__ = () => import("/src/pages/auth/index.vue");
import __pages_import_13__ from "/src/pages/index.vue";
const __pages_import_14__ = () => import("/src/pages/events/create/[uuid].vue");

const routes = [{"name":"events-main-scanner","path":"/events/main/scanner","component":__pages_import_0__,"props":true},{"name":"success","path":"/success","component":__pages_import_1__,"props":true},{"name":"reports-useReportData","path":"/reports/usereportdata","component":__pages_import_2__,"props":true},{"name":"reports-useChartOptions","path":"/reports/usechartoptions","component":__pages_import_3__,"props":true},{"name":"reports-useChartCreation","path":"/reports/usechartcreation","component":__pages_import_4__,"props":true},{"name":"reports","path":"/reports","component":__pages_import_5__,"props":true},{"name":"reports-RoutineCyclesReport","path":"/reports/routinecyclesreport","component":__pages_import_6__,"props":true},{"name":"link","path":"/link","component":__pages_import_7__,"props":true},{"name":"error","path":"/error","component":__pages_import_8__,"props":true},{"name":"dashboard-useExport","path":"/dashboard/useexport","component":__pages_import_9__,"props":true},{"name":"dashboard-useEvents","path":"/dashboard/useevents","component":__pages_import_10__,"props":true},{"name":"dashboard","path":"/dashboard","component":__pages_import_11__,"props":true},{"name":"auth","path":"/auth","component":__pages_import_12__,"props":true},{"name":"index","path":"/","component":__pages_import_13__,"props":true},{"name":"events-create-uuid","path":"/events/create/:uuid","component":__pages_import_14__,"props":true}];

export default routes;