/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

// Plugins
import vuetify from './vuetify'
import http from './http'
import pinia from '../stores'
import router from '../router'
import VueQrcode from '@chenfengyuan/vue-qrcode';
import Vue3Lottie from 'vue3-lottie'
import VueSignaturePad from 'vue-signature-pad';
import TreeSelect from '@r2rka/vue3-treeselect'
import '@r2rka/vue3-treeselect/dist/style.css'
import { VTreeview } from 'vuetify/labs/VTreeview'

// Types
import type { App } from 'vue'
import { i18next, I18NextVue } from '../i18n';

export function registerPlugins (app: App) {
  app.component('qr-code', VueQrcode)
  app.component('TreeSelect', TreeSelect)
  app.component('VTreeview', VTreeview)
  app
    .use({
      install: (app: App) => {
        function debounceEvent(time: any = null) {
          return function(fn: Function, wait = 1000) {
            clearTimeout(time)

            time = setTimeout(() => {
              fn()
            }, wait)
          }
        }

        app.provide('$http', http.$http)
        app.provide('$account', http.$account)
        app.provide('$debounce', debounceEvent())
      }
    })
    
    .use(vuetify)
    .use(router)
    .use(pinia)
    .use(Vue3Lottie)
    .use(I18NextVue, { i18next })
    .use(VueSignaturePad);
}
