import i18next from "i18next";
import  I18NextVue  from "i18next-vue";

// Define translation resources
const resources = {
  en: {
    translation: {
      welcome: "Welcome",
      search: "Search",
      registeredTasks: "Registered tasks",
      currentMonth: "Current month",
      lastMonth: "Last month",
      last30Days: "Last 30 days",
      last15Days: "Last 15 days",
      searchByCode: "Search by code",
      searchByCodeAction: "Type the task code",
      byProject: "By project",
      applyFilter: "Apply filter",
      clearFilter: "Clear filter",
      showAdvancedFilter: "Show advanced filter",
      hideAdvancedFilter: "Hide advanced filter",
      byProjectAction: "Select a project",
      byArea: "By area",
      byAreaAction: "Select an area",
      byTask: "By task",
      byTaskAction: "Select a task",
      byUsers: "By users",
      byCategory: "By category",
      byCategoryAction: "Select a category",
      startDate: "Start date",
      endDate: "End date",
      selectStartDate: "Select start date",
      selectEndDate: "Select end date",
      "eventCounter": "{{eventCount}} of {{total}} tasks listed",
      "pageCounter": "Page {{page}} of {{total}}",
      "nextPage": "Next page",
      "previousPage": "Previous page",
      eventImage: "Image",
      eventCode: "Code",
      eventAsset: "Asset",
      eventTask: "Task",
      eventLocation: "Location",
      registerDetail: "Register details",
      eventCreated: "Created at",
      eventUser: "User",
      reports: "Reports",
      "events": "Tasks",
      "noLocation": "No location",
      "exportToExcel": "Export to Excel",
      "exportToPDF": "Export to PDF",
      "yes": "Yes",
      "no": "No",
      additionalInfos: "Additional information",
      eventDetails: "Task details",
      noAnswer: "No answer",
      eventImages: "Task images",
      close: "Close",
      selectDateRange: "Period",
      columns: {
        image: "Image",
        code: "Code",
        asset: "Asset",
        task: "Task",
        location: "Location",
        created: "Created at",
        timeToFill: "Time to fill",
        areaBuilding: "Area/Building",
        areaFloor: "Floor",
        areaSector: "Sector",
        assetName: "Asset",
        categoryName: "Category",
        status: "Status",
        user
        : "Registered by",
        typeName: "Task",
        closed: "Closed",
        pending: "Pending",
      }
    },
  },
  "pt-BR": {
    translation: {
      welcome: "Bem-vindo",
      search: "Buscar",
      registeredTasks: "Tarefas registradas",
      currentMonth: "Mês atual",
      lastMonth: "Mês anterior",
      last30Days: "Últimos 30 dias",
      last15Days: "Últimos 15 dias",
      searchByCode: "Buscar por código",
      searchByCodeAction: "Digite o código da tarefa",
      byProject: "Por projeto",
      applyFilter: "Aplicar filtro",
      clearFilter: "Limpar filtro",
      showAdvancedFilter: "Mostrar filtro avançado",
      hideAdvancedFilter: "Ocultar filtro avançado",
      byProjectAction: "Selecione um projeto",
      byArea: "Por área",
      byAreaAction: "Selecione uma área",
      byTask: "Por tarefa",
      byTaskAction: "Selecione uma tarefa",
      byUsers: "Por usuários",
      byCategory: "Por categoria",
      byCategoryAction: "Selecione uma categoria",
      startDate: "Data de início",
      endDate: "Data de término",
      selectStartDate: "Selecione a data de início",
      selectEndDate: "Selecione a data de término",
      "eventCounter": "{{eventCount}} de {{total}} tarefas listadas",
      "pageCounter": "Página {{page}} de {{total}}",
      "nextPage": "Próxima página",
      "previousPage": "Página anterior",
      eventImage: "Imagem",
      eventCode: "Código",
      eventAsset: "Ativo",
      eventTask: "Tarefa",
      eventLocation: "Localização",
      noAdditionalInfo: "Sem informações adicionais",
      eventType: "Tipo",
      executedServicesReport: "Relatório de serviços executados",
      showing: "Mostrando",
      of: "de",
      registerDetail: "Detalhes do Registro",
      eventCreated: "Criado em",
      eventUser: "Usuário",
      reports: "Relatórios",
      "events": "Tarefas",
      "noLocation": "Sem localização",
      "exportToExcel": "Exportar para Excel",
      "exportToPDF": "Exportar para PDF",
      "yes": "Sim",
      "no": "Não",
      additionalInfos: "Informações adicionais",
      eventDetails: "Detalhes da tarefa",
      noAnswer: "Sem resposta",
      eventImages: "Imagens da tarefa",
      close: "Fechar",
      selectDateRange: "Período",
      columns: {
        image: "Imagem",
        code: "Código",
        asset: "Ativo",
        task: "Tarefa",
        location: "Localização",
        created: "Criado em",
        timeToFill: "Tempo para preenchimento",
        areaBuilding: "Área/Edifício",
        areaFloor: "Andar",
        areaSector: "Setor",
        assetName: "Ativo",
        categoryName: "Categoria",
        status: "Status",
        user: "Registrado por",
        typeName: "Tarefa",
        closed: "Concluído",
        pending: "Pendente",
      },
      task: "Tarefa",
      incident: "Chamado",
    },
  },
};


i18next.init({
  resources,
  lng: "pt-BR",
  fallbackLng: "pt-BR",
  interpolation: {
    escapeValue: false, 
    prefix: '{{',
    suffix: '}}', 
  },
});

export { i18next, I18NextVue };