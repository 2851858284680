<template>
  <div class="app-container">
    <HeaderToolbar title="Controlsys Work" :hide-back-button="true" class="header-toolbar" />
    
    <div class="content-container">
      <div class="app-grid">
        <div v-for="(button, index) in buttons" :key="index" class="app-item">
          <button 
            class="app-button" 
            :class="`app-button--${button.style || 'default'}`"
            @click="button.action"
            :aria-label="button.label"
          >
            <div class="app-icon-wrapper">
              <v-icon size="36">{{ button.icon }}</v-icon>
            </div>
            <span class="app-label">{{ button.label }}</span>
          </button>
        </div>
      </div>
    </div>

    <!-- Feature highlight section
    <div class="feature-highlight">
      <h2 class="feature-title">Bem-vindo ao Controlsys Work</h2>
    <p class="feature-description">Gerenciamento simplificado com uma interface intuitiva e elegante.</p>
    </div> -->
    
    <v-dialog v-model="dialog" persistent max-width="340px" content-class="apple-dialog">
      <v-card class="apple-card">
        <v-card-title class="apple-card-title">Não disponível</v-card-title>
        <v-card-text class="apple-card-text">Esta funcionalidade está em desenvolvimento!</v-card-text>
        <v-card-actions class="apple-card-actions">
          <v-spacer></v-spacer>
          <v-btn 
            color="primary" 
            class="apple-button" 
            @click="closeModal"
          >
            Fechar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts" setup>
import { useRouter } from 'vue-router';
import { ref, inject, onMounted } from 'vue';
import HeaderToolbar from '../components/HeaderToolbar.vue';

const $account = inject('$account') as Function;
const url = ref(`https://${$account()}.controlsys.app.br`);
const router = useRouter();
const dialog = ref(false);

const closeModal = () => {
  dialog.value = false;
}

const navigate = (path: string, isExternal = false) => {
  if (isExternal) {
    window.open(path, '_blank');
  } else {
    router.push(path);
  }
}

// Enhanced button data with Apple-style customizations
const buttons = [
  { 
    label: 'Links', 
    icon: 'mdi-link-variant', 
    color: 'blue', 
    style: 'blue',
    action: () => navigate('/link') 
  },
  { 
    label: 'Controlsys', 
    icon: 'mdi-application', 
    color: 'purple', 
    style: 'purple',
    action: () => navigate(url.value, true) 
  },
  { 
    label: 'Tarefas / Chamados', 
    icon: 'mdi-note-text', 
    color: 'teal', 
    style: 'teal',
    action: () => navigate('/dashboard') 
  },
  { 
    label: 'Analytics', 
    icon: 'mdi-chart-bar', 
    color: 'orange', 
    style: 'orange',
    action: () => navigate('/reports') 
  },
  // { 
  //   label: 'Settings', 
  //   icon: 'mdi-cog-outline', 
  //   color: 'gray', 
  //   style: 'gray',
  //   action: openModal 
  // },
  // { 
  //   label: 'Profile', 
  //   icon: 'mdi-account-circle-outline', 
  //   color: 'red', 
  //   style: 'red',
  //   action: openModal 
  // }
];

// Add animation after mount
onMounted(() => {
  document.querySelectorAll('.app-item').forEach((item, index) => {
    setTimeout(() => {
      item.classList.add('appear');
    }, 100 * index);
  });
});
</script>

<style scoped lang="scss">
// Apple-inspired design system variables
:root {
  --font-primary: -apple-system, BlinkMacSystemFont, 'SF Pro Text', 'Helvetica Neue', sans-serif;
  --border-radius-large: 16px;
  --border-radius-medium: 12px;
  --border-radius-small: 8px;
  
  // Apple colors
  --blue: #007AFF;
  --purple: #5E5CE6;
  --teal: #64D2FF;
  --orange: #FF9500;
  --red: #FF3B30;
  --gray: #8E8E93;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #F2F2F7;
  font-family: -apple-system, BlinkMacSystemFont, 'SF Pro Text', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  transition: background-color 0.3s;
}

.header-toolbar {
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.85) !important;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.05) !important;
}

.content-container {
  flex: 1;
  padding: 24px;
  padding-top: 32px;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.app-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  padding: 12px 0;
  
  @media (min-width: 600px) {
    grid-template-columns: repeat(3, 1fr);
  }
  
  @media (min-width: 960px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.app-item {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
  
  &.appear {
    opacity: 1;
    transform: translateY(0);
  }
}

.app-button {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 8px;
  transition: transform 0.2s cubic-bezier(0.34, 1.56, 0.64, 1);
  
  &:active {
    transform: scale(0.95);
  }
  
  &:focus-visible {
    outline: 2px solid var(--blue);
    border-radius: var(--border-radius-medium);
  }
}

.app-icon-wrapper {
  background-color: white;
  width: 68px;
  height: 68px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  @media (min-width: 600px) {
    width: 84px;
    height: 84px;
    border-radius: 20px;
  }
  
  .app-button:hover & {
    transform: translateY(-4px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  }
}

// App button style variations
.app-button--blue .app-icon-wrapper {
  background: linear-gradient(135deg, #0A84FF, #0055D6);
  color: white;
}

.app-button--purple .app-icon-wrapper {
  background: linear-gradient(135deg, #5E5CE6, #3634A3);
  color: white;
}

.app-button--teal .app-icon-wrapper {
  background: linear-gradient(135deg, #64D2FF, #00A2E0);
  color: white;
}

.app-button--orange .app-icon-wrapper {
  background: linear-gradient(135deg, #FF9F0A, #E06900);
  color: white;
}

.app-button--red .app-icon-wrapper {
  background: linear-gradient(135deg, #FF453A, #D00000);
  color: white;
}

.app-button--gray .app-icon-wrapper {
  background: linear-gradient(135deg, #8E8E93, #636366);
  color: white;
}

.app-label {
  font-size: 14px;
  margin-top: 6px;
  font-weight: 500;
  color: #1C1C1E;
}

// Feature highlight section
.feature-highlight {
  text-align: center;
  padding: 32px 24px;
  background-color: white;
  margin-top: 24px;
  border-radius: 16px;
  margin: 16px 24px 32px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.05);
}

.feature-title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 8px;
  background: linear-gradient(90deg, #007AFF, #5E5CE6);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feature-description {
  font-size: 16px;
  line-height: 1.5;
  color: #636366;
  max-width: 500px;
  margin: 0 auto;
}

// Apple-style dialog
.apple-dialog {
  border-radius: 14px;
  overflow: hidden;
}

.apple-card {
  border-radius: 14px;
  padding: 12px;
}

.apple-card-title {
  font-size: 18px !important;
  font-weight: 600 !important;
  text-align: center;
  padding-top: 12px;
}

.apple-card-text {
  text-align: center;
  color: #636366;
  font-size: 16px;
  padding: 16px 0;
}

.apple-card-actions {
  display: flex;
  justify-content: center;
  padding-bottom: 8px;
}

.apple-button {
  font-weight: 500 !important;
  text-transform: none !important;
  letter-spacing: 0 !important;
  font-size: 16px !important;
}
</style>